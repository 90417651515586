'use strict'

var State = require('dover')
var Event = require('weakmap-event')

var h = require('virtual-dom/h')
var clickEvent = require('value-event/click')
var zoom = require('./zoom')

module.exports = ImageButton

function ImageButton (data) {
  data = data || {}

  return State({
    channels: {
      click: ClickEvent.broadcast
    }
  })
}

var ClickEvent = Event()
ImageButton.onClick = ClickEvent.listen

ImageButton.render = function render (state, options) {
  options = options || {}

  var style = {
    position: 'absolute',
    top: '8px',
    right: '8px',
    width: '32px',
    height: '32px',
    padding: '8px',
    background: 'rgba(0,0,0,.8)',
    color: 'white',
    borderRadius: '3px'
  }

  var content = !options.zoomed ? zoom.in : zoom.out

  return h('button', {
    style: style,
    'ev-click': clickEvent(state.channels.click)
  }, content.render())
}
