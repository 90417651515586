'use strict'

var Label = require('./label')

module.exports = CompoundsLabel

function CompoundsLabel () {
  return Label()
}

CompoundsLabel.onClick = Label.onClick

CompoundsLabel.render = function render (state, data, options) {
  data = data || {}
  options = options || {}

  if (!data.thc.content && !data.cbd.content) return

  return Label.render(state, {
    large: options.large,
    title: concat([
      renderCompound('THC', data.thc),
      renderCompound('CBD', data.cbd)
    ]),
    color: '#CFD8DC'
  })
}

function renderCompound (name, data) {
  return data && data.content ? name + ': ' + data.content + data.unit : null
}

function concat (items) {
  return items.filter(Boolean).join(' ')
}
