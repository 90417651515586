'use strict'

var h = require('virtual-dom/h')
var prefix = require('preflex')
var Increment = require('./increment')
var Fixed = require('./fixed')

module.exports = {
  render: render,
  selected: selected
}

function render (state, data, options) {
  var style = prefix({
    display: 'flex',
    alignItems: 'stretch',
    height: options.large ? '64px' : '54px'
  })

  return h('purchase-controls', {style: style}, [
    Array.isArray(data.prices)
      ? Fixed.render(state, data, options)
      : Increment.render(state, data, options)
  ])
}

function selected (state, data) {
  var quantity = state.quantity()

  return !Array.isArray(data.prices) || quantity
    ? quantity || 1
    : data.prices[0].quantity
}
