'use strict'

var svg = require('virtual-dom/virtual-hyperscript/svg')

exports.render = function render (text, style) {
  return svg('svg', {
    width: '50px',
    height: '32px',
    viewBox: '0 0 50 32',
    style: {
      // fix sloppy Sketch svgs
      overflow: 'visible'
    }
  }, [
    svg('path', {
      style: {
        fill: style.background,
        stroke: style.color
      },
      d: 'M0,16 L6.94789826,2.66003534 C7.71305292,1.19093839 9.67246654,0 11.3298972,0 L47.0034361,0 C48.6583926,0 50,1.33425951 50,2.99610022 L50,29.0038998 C50,30.6586002 48.6608668,32 47.0034361,32 L11.3298972,32 C9.67494069,32 7.71378706,30.8104711 6.94789826,29.3399647 L0,16 Z'
    }),
    svg('text', {
      fill: style.color,
      x: 25 - (4 * text.length),
      y: 22,
      style: {
        fontWeight: style.fontWeight
      }
    }, text)
  ])
}
