
'use strict'

const pipe = require('../value-pipe.js')
const toCss = require('to-css')
const partial = require('ap').partial
const format = require('../simple-format')
const dataUri = require('../create-data-uri')

// https://github.com/bendrucker/object-pair/blob/master/index.js
function pair (key, value) {
  const obj = {}
  obj[key] = value
  return obj
}

// https://github.com/bendrucker/insert-fonts/blob/master/create.js
module.exports = pipe(createFace, partial(pair, '@font-face'), toCss)

function createFace (data) {
  return {
    'font-family': data.family,
    src: createSrc(data.font),
    'font-style': 'normal',
    'font-weight': data.weight
  }
}

function createSrc (font) {
  return format(
    "url(%s) format('woff')",
    dataUri({ type: 'application/font-woff', charset: 'utf-8' }, font)
  )
}
