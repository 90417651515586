'use strict'

var Label = require('./label')
var colors = require('@eaze/palette/strains')

module.exports = CategoryLabel

function CategoryLabel () {
  return Label()
}

CategoryLabel.onClick = Label.onClick

CategoryLabel.render = function render (state, data, options) {
  data = data || {}
  options = options || {}

  return Label.render(state, {
    large: options.large,
    title: data.title,
    color: colors[data.key] || colors[data.parent.key],
    data: data
  })
}
