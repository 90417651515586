// https://github.com/bendrucker/insert-fonts/blob/master/index.js

'use strict'

const flatten = require('flatten')
const insertStyles = require('../insert-styles/index')
const createCss = require('./create')

module.exports = insertFonts

function insertFonts (fonts) {
  const css = flatten(Object.keys(fonts).map(function (family) {
    return Object.keys(fonts[family]).map(function (weight) {
      return createCss({
        family: family,
        weight: weight,
        font: fonts[family][weight]
      })
    })
  }))
    .join('\n')

  insertStyles(css)

  return css
}
