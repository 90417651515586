'use strict'

var h = require('virtual-dom/h')
var prefix = require('preflex')
var clickEvent = require('value-event/click')
var extend = require('xtend')
var format = require('simple-format')

var findIndex = require('find-index')

module.exports = {
  render: render
}

function render (state, data, options) {
  options = options || {}

  var style = prefix({
    display: 'flex',
    alignItems: 'stretch',
    position: 'relative',
    width: '100%'
  })

  return h('div', {style: style}, [
    renderButtons(state, data, options),
    renderArrow(data.prices.length, quantityIndex(state.quantity, data.prices), options.large)
  ])
}

function renderButtons (state, data, options) {
  var style = prefix({
    display: 'flex',
    alignItems: 'stretch',
    justifyContent: 'center',
    width: '100%'
  })

  var quantity = state.quantity

  return h('div', {style: style}, data.prices.reduce(function (acc, item, index, array) {
    var selected = quantityIndex(quantity, data.prices) === index
    var nextSelected = quantityIndex(quantity, data.prices) === index + 1

    return acc.concat([
      renderButton(state, extend(item, {
        selected: selected,
        large: options.large
      })),
      index !== array.length - 1 ? renderDivider(selected || nextSelected) : undefined
    ])
  }, []))
}

function renderButton (state, data) {
  var style = prefix({
    flex: 1,
    textAlign: 'center',
    padding: '4px',
    paddingBottom: '8px',
    background: data.selected ? '#F2F6FE' : 'white'
  })

  var options = {
    'ev-click': clickEvent(state.channels.set, {
      quantity: data.quantity
    }),
    style: {
      width: '100%'
    }
  }

  return h('div', {style: style}, h('button', options, [
    renderQuantity(state, data),
    renderPrice(state, data)
  ]))
}

function renderPrice (state, data) {
  var style = {
    fontSize: data.large ? '18px' : '16px',
    color: '#607D8C'
  }

  return h('h4', {style: style}, '$' + data.cost)
}

function renderQuantity (state, data) {
  var style = {
    fontSize: data.large ? '20px' : '18px',
    fontWeight: 500,
    color: '#263238'
  }
  return h('p', {style: style}, data.label)
}

function renderDivider (selected) {
  return h('divider', {
    style: {
      display: 'block',
      width: '1px',
      background: selected ? '#567BE4' : '#CFD8DC'
    }
  })
}

function renderArrow (length, index, large) {
  var size = large ? '4px' : '5px'
  var style = {
    display: 'block',
    width: 0,
    height: 0,
    position: 'absolute',
    bottom: 0,
    left: format('calc(%s - %s)', (1 + 2 * index) / (2 * length) * 100 + '%', size),
    borderLeft: size + ' solid transparent',
    borderRight: size + ' solid transparent',
    borderBottom: size + ' solid #567BE4'
  }

  return h('pointer', {style: style})
}

function quantityIndex (quantity, prices) {
  return quantity == null ? 0 : findIndex(prices, matchQuantity(quantity))
}

function matchQuantity (quantity) {
  return function (price) {
    return price.quantity === quantity
  }
}
