'use strict'

var State = require('dover')
var Observ = require('observ')
var Event = require('weakmap-event')

var h = require('virtual-dom/h')
var clickEvent = require('value-event/click')
var mousedownEvent = require('value-event-mouse/down')
var mouseupEvent = require('value-event-mouse/up')
var mouseleaveEvent = require('value-event-mouse/leave')
var event = require('value-event/event')

module.exports = PurchaseButton

function PurchaseButton (data) {
  data = data || {}

  var state = State({
    active: Observ(data.active || false),
    channels: {
      active: active,
      click: ClickEvent.broadcast
    }
  })

  return state
}

function active (state, data) {
  state.active.set(data.active)
}

var ClickEvent = Event()
PurchaseButton.onClick = ClickEvent.listen

PurchaseButton.render = function render (state, data) {
  var style = {
    background: state.active ? '#4C6CBE' : '#587EE1',
    color: state.active ? 'rgba(255, 255, 255, 0.6)' : 'white',
    fontSize: '1em',
    width: '100%',
    textAlign: 'center',
    padding: '1em',
    fontWeight: 500,
    transition: 'background, color .1s ease-out'
  }

  return h('button', {
    'ev-click': clickEvent(state.channels.click, {id: data.id, prices: data.prices}),
    'ev-mousedown': mousedownEvent(state.channels.active, {active: true}),
    'ev-mouseup': mouseupEvent(state.channels.active, {active: false}),
    'ev-mouseleave': mouseleaveEvent(state.channels.active, {active: false}),
    'ev-touchstart': event(state.channels.active, {active: true}),
    'ev-touchend': event(state.channels.active, {active: false}),
    style: style
  }, 'Add to cart')
}
