'use strict'

// https://github.com/bendrucker/assert-ok/blob/master/index.js
function assert (value, message) {
  if (!value) {
    throw new Error(message || 'Expected true, got ' + value)
  }
}

// https://github.com/bendrucker/create-data-uri/blob/master/index.js
module.exports = function createDataUri (options, data) {
  const type = typeof options === 'object'
    ? [options.type, options.charset].filter(Boolean).join(';charset=')
    : options

  assert(typeof type === 'string', 'mime type required')
  assert(data, 'data required')

  return ['data:' + type, 'base64,' + data].join(';')
}
