'use strict'

const path = require('path')
const fs = require('fs')

module.exports = {
  300: fs.readFileSync(path.resolve(__dirname, 'fonts/brandon-light.woff'), 'base64'),
  400: fs.readFileSync(path.resolve(__dirname, 'fonts/brandon-regular.woff'), 'base64'),
  500: fs.readFileSync(path.resolve(__dirname, 'fonts/brandon-medium.woff'), 'base64')
}
