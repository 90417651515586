'use strict'

const getObject = require('./object')
const toCss = require('to-css')
const insertStyles = require('./insert-styles')

module.exports = function reset () {
  const css = toCss(getObject())

  insertStyles(css)

  return css
}
