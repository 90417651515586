'use strict'

var Struct = require('observ-struct')
var bubble = require('weak-bubble')
var Event = require('weakmap-event')
var pipe = require('value-pipe')
var pair = require('object-pair')
var extend = require('xtend')
var partial = require('ap').partial

var h = require('virtual-dom/h')
var prefix = require('preflex')
var Keyframes = require('create-keyframes')
var when = require('value-when')

var Category = require('./category')
var Compounds = require('./compounds')
var Description = require('./description')
var Image = require('./image')
var Purchase = require('./purchase')

module.exports = Product

function Product (data) {
  data = data || {}

  var state = Struct({
    category: Category(),
    compounds: Compounds(),
    description: Description(data.description),
    image: Image(data.image),
    purchase: Purchase(data.purchase)
  })

  Category.onClick(state.category, pipe([
    partial(pair, 'category'),
    partial(extend, {type: 'category'}),
    partial(InfoEvent.broadcast, state)
  ]))
  Compounds.onClick(state.compounds, partial(InfoEvent.broadcast, state, {type: 'compounds'}))

  return state
}

Product.onAdd = bubble({
  purchase: Purchase.onAdd
})

var InfoEvent = Event()
Product.onInfo = InfoEvent.listen

Product.render = function render (state, data, options) {
  data = data || {}
  options = options || {}

  var style = {
    container: {
      position: 'relative',
      height: options.large ? '472px' : '304px',
      borderRadius: '3px',
      overflow: 'hidden'
    },
    content: prefix({
      position: 'relative',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
      zIndex: 10,
      padding: options.large ? '16px' : '8px',
      color: 'white'
    })
  }

  return h('.product', {style: style.container}, [
    h('div', {style: style.content}, renderProduct(state, data, options)),
    Image.renderPhoto(state.image, data.photo),
    renderGradient(state, options)
  ])
}

function renderProduct (state, data, options) {
  var style = prefix({
    flexShrink: 0,
    opacity: Number(!state.image.zoomed),
    pointerEvents: when(state.image.zoomed, 'none'),
    transition: 'opacity .1s ease-in'
  })

  return [
    renderTag(state, data, options),
    Image.renderButton(state.image),
    h('div', {style: style}, [
      renderInfo(state, data, options),
      Purchase.render(state.purchase, {id: data.id, prices: data.prices}, options)
    ])
  ]
}

var flyIn = Keyframes({
  from: {
    transform: 'rotate(-45deg) translateY(-48px)',
    opacity: 0.5
  },
  to: {
    transform: 'rotate(-45deg) translateY(0)',
    opacity: 1
  }
})

function renderTag (state, data, options) {
  if (!data.added) return

  return h('div', {
    style: prefix({
      position: 'absolute',
      top: '-28px',
      left: '-42px',
      height: '72px',
      width: '96px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'flex-end',
      fontSize: options.large ? '14px' : '10px',
      padding: '8px',
      transform: 'rotate(-45deg)',
      webkitTransform: 'rotate(-45deg)',
      fontWeight: 500,
      color: 'white',
      backgroundColor: '#63C067',
      borderTopLeftRadius: '3px',
      animation: flyIn + ' 0.5s ease-out'
    })
  }, 'Added')
}

function renderInfo (state, data, options) {
  var style = {
    padding: options.large ? '8px 16px' : '8px'
  }

  return h('div', {style: style}, [
    renderTitle(data, options),
    renderLabels(state, data, options),
    Description.render(state.description, {
      text: data.description
    })
  ])
}

function renderTitle (data, options) {
  var style = {
    fontSize: options.large ? '28px' : '20px',
    marginBottom: '16px',
    fontWeight: 500,
    textShadow: '0 0 10px black'
  }
  return h('h2', {style: style}, data.title)
}

function renderLabels (state, data, options) {
  return h('.labels', [
    Category.render(state.category, data.category, options),
    Compounds.render(state.compounds, data.compounds, options)
  ])
}

function renderGradient (state, options) {
  return h('div.gradient', {
    style: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '3px',
      overflow: 'hidden',
      background: 'linear-gradient(-180deg, transparent 20%, rgba(0, 0, 0, .7) 50%)',
      opacity: state.image.zoomed
        ? 0
        : state.description.expanded && !options.large
          ? 1
          : 0.7,
      transition: 'opacity .1s ease-in'
    }
  })
}
