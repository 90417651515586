'use strict'

var svg = require('virtual-dom/virtual-hyperscript/svg')

module.exports = {
  in: {
    render: renderIn
  },
  out: {
    render: renderOut
  }
}

function renderIn () {
  return svg('svg', {
    style: {
      width: '1em',
      height: '1em',
      fill: 'white'
    },
    width: '128px',
    height: '128px',
    viewBox: [0, 0, 128, 128].join(' ')
  }, svg('path', {
    d: 'M62.828,81.172 C63.609,81.954 64,82.979 64,84.003 C64,85.026 63.609,86.048 62.828,86.828 L41.656,108 L54.828,121.172 C55.592,121.936 55.999,122.957 56,123.997 C56.002,124.514 55.901,125.035 55.695,125.531 C55.076,127.026 53.617,128 52,128 L4,128 C1.791,128 0,126.209 0,124 L0,76 C0,74.383 0.974,72.924 2.469,72.305 C2.965,72.1 3.485,72 3.999,72 C5.04,72 6.063,72.406 6.828,73.172 L20,86.344 L41.172,65.172 C41.953,64.391 42.977,64 44,64 C45.023,64 46.047,64.391 46.828,65.172 L62.828,81.172 L62.828,81.172 Z M124,0 L76,0 C74.383,0 72.924,0.975 72.305,2.469 C72.1,2.964 72,3.484 72,3.998 C72,5.039 72.406,6.064 73.172,6.828 L86.344,20 L65.172,41.172 C64.391,41.952 64,42.975 64,43.997 C64,45.022 64.391,46.046 65.172,46.828 L81.172,62.828 C81.953,63.609 82.977,64 84,64 C85.024,64 86.047,63.609 86.828,62.828 L108,41.656 L121.172,54.828 C121.938,55.594 122.96,56 124.002,56 C124.516,56 125.035,55.901 125.531,55.695 C127.026,55.076 128,53.618 128,52 L128,4 C128,1.791 126.209,0 124,0 L124,0 Z'
  }))
}

function renderOut () {
  return svg('svg', {
    style: {
      width: '1em',
      height: '1em',
      fill: 'white'
    },
    width: '128px',
    height: '128px',
    viewBox: [0, 0, 128, 128].join(' ')
  }, svg('path', {
    d: 'M1.172,111.161 C0.391,110.38 1.14487328e-13,109.355 1.14487328e-13,108.331 C1.14487328e-13,107.308 0.391,106.285 1.172,105.505 L14.344,92.334 L1.172,79.162 C0.408,78.398 0.001,77.376 1.14487328e-13,76.337 C-0.002,75.82 0.098,75.298 0.305,74.802 C0.924,73.308 2.383,72.333 4,72.333 L52,72.333 C54.209,72.333 56,74.125 56,76.333 L56,124.333 C56,125.95 55.025,127.41 53.531,128.029 C53.035,128.234 52.516,128.334 52.001,128.334 C50.96,128.334 49.937,127.927 49.172,127.162 L36,113.99 L22.828,127.162 C22.047,127.943 21.023,128.334 20,128.334 C18.976,128.334 17.953,127.943 17.172,127.162 L1.172,111.161 L1.172,111.161 Z M76,56.334 L124,56.334 C125.617,56.334 127.076,55.359 127.695,53.865 C127.9,53.37 128,52.85 128,52.335 C128,51.295 127.594,50.27 126.828,49.505 L113.656,36.333 L126.828,23.162 C127.609,22.381 128,21.359 128,20.336 C128,19.312 127.609,18.287 126.828,17.505 L110.828,1.505 C110.047,0.724 109.023,0.333 108,0.333 C106.977,0.333 105.953,0.724 105.172,1.505 L92,14.677 L78.828,1.505 C78.062,0.74 77.04,0.333 75.998,0.333 C75.484,0.333 74.965,0.433 74.469,0.638 C72.975,1.257 72,2.716 72,4.333 L72,52.333 C72,54.543 73.791,56.334 76,56.334 L76,56.334 Z'
  }))
}
