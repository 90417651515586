'use strict'

var State = require('dover')
var Event = require('weakmap-event')

var h = require('virtual-dom/h')
var clickEvent = require('value-event/click')

module.exports = Label

function Label () {
  return State({
    channels: {
      click: ClickEvent.broadcast
    }
  })
}

var ClickEvent = Event()
Label.onClick = ClickEvent.listen

Label.render = function render (state, options) {
  var button = {
    'ev-click': clickEvent(state.channels.click, options.data),
    style: {
      position: 'inline-block',
      padding: options.large ? '8px 16px' : '4px 8px',
      marginRight: '8px',
      marginBottom: '8px',
      fontSize: options.large ? '14px' : '10px',
      letterSpacing: '0.5px',
      background: 'rgba(0, 0, 0, 0.4)',
      fontWeight: 500,
      color: options.color,
      border: '1px solid ' + options.color,
      borderRadius: '40px',
      textTransform: 'uppercase'
    }
  }

  return h('button', button, options.title)
}
