'use strict'

var State = require('dover')
var Observ = require('observ')
var Event = require('weakmap-event')

var h = require('virtual-dom/h')
var Button = require('./button')
var Controls = require('./controls')

module.exports = Purchase

function Purchase (data) {
  data = data || {}

  var state = State({
    quantity: Observ(data.quantity),
    button: Button(),
    channels: {
      set: set
    }
  })

  Button.onClick(state.button, function onClick (data) {
    AddEvent.broadcast(state, {
      id: data.id,
      quantity: Controls.selected(state, data)
    })
    reset(state)
  })

  return state
}

var AddEvent = Event()
Purchase.onAdd = AddEvent.listen

function set (state, data) {
  state.quantity.set(data.quantity)
}

function reset (state) {
  state.quantity.set(null)
}

Purchase.render = function render (state, data, options) {
  var style = {
    borderRadius: '3px',
    overflow: 'hidden'
  }

  return h('div', {style: style}, [
    Controls.render(state, data, options),
    Button.render(state.button, data, options)
  ])
}
