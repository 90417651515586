'use strict'

var h = require('virtual-dom/h')
var prefix = require('preflex')
var clickEvent = require('value-event/click')
var assign = require('xtend/mutable')
var icons = require('./icons')
var tag = require('./tag')

module.exports = {
  render: render
}

function render (state, data, options) {
  options = options || {}

  var style = prefix({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    padding: options.large ? '16px' : '8px 16px',
    background: 'white'
  })

  return h('div', {style: style}, [
    renderButton(state, -1),
    renderSelection(state, data),
    renderButton(state, 1)
  ])
}

function renderButton (state, increment) {
  var options = {
    style: {
      width: '100%',
      padding: '.5em 0',
      color: '#607D8B'
    },
    'ev-click': clickEvent(state.channels.set, {
      quantity: quantity(state) + increment
    })
  }

  if (!allowed(state, increment)) {
    assign(options.style, {
      color: '#aac2cd',
      cursor: 'default'
    })
  }

  var icon = icons[increment > 0 ? 'plus' : 'minus']

  return h('button', options, icon.render())
}

function renderSelection (state, data) {
  var style = prefix({
    display: 'flex',
    alignItems: 'center',
    flexShrink: 0,
    margin: '0 2em'
  })

  return h('div', {style: style}, [
    renderQuantity(state, data),
    renderCost(state, data)
  ])
}

function renderQuantity (state) {
  var style = {
    color: '#263238',
    fontWeight: 500,
    marginRight: '.5em',
    whiteSpace: 'nowrap'
  }

  return h('p', {style: style}, quantity(state))
}

function renderCost (state, data) {
  return tag.render('$' + quantity(state) * data.prices.cost, {
    color: '#587EE1',
    background: '#F2F6FE',
    fontWeight: 500
  })
}

function allowed (state, increment) {
  return quantity(state) + increment > 0
}

function quantity (state) {
  return state.quantity || 1
}
