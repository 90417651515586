'use strict'

var svg = require('virtual-dom/virtual-hyperscript/svg')

module.exports = {
  plus: {
    render: renderPlus
  },
  minus: {
    render: renderMinus
  }
}

function renderPlus () {
  return svg('svg', {
    width: '129px',
    height: '128px',
    viewBox: [0, 0, 129, 128].join(' '),
    style: {
      width: '1em',
      height: '1em'
    },
    fill: 'currentColor'
  }, svg('path', {
    d: 'M120.119,48 L80.119,48 L80.119,8 C80.119,3.582 76.537,0 72.119,0 L56.119,0 C51.701,0 48.119,3.582 48.119,8 L48.119,48 L8.119,48 C3.701,48 0.119,51.582 0.119,56 L0.119,72 C0.119,76.418 3.701,80 8.119,80 L48.119,80 L48.119,120 C48.119,124.418 51.701,128 56.119,128 L72.119,128 C76.537,128 80.119,124.418 80.119,120 L80.119,80 L120.119,80 C124.537,80 128.119,76.418 128.119,72 L128.119,56 C128.119,51.582 124.537,48 120.119,48'
  }))
}

function renderMinus () {
  return svg('svg', {
    width: '128px',
    height: '32px',
    viewBox: [0, 0, 128, 32].join(' '),
    style: {
      width: '1em',
      height: '1em'
    },
    fill: 'currentColor'
  }, svg('path', {
    d: 'M120,0 C124.418,0 128,3.582 128,8 L128,24 C128,28.418 124.418,32 120,32 L8,32 C3.582,32 0,28.418 0,24 L0,8 C0,3.582 3.582,0 8,0 L120,0'
  }))
}
