// https://github.com/bendrucker/simple-format/blob/master/index.js
'use strict'

const printf = require('pff')
const toArray = require('to-array')
const regex = /%[sdj]/

module.exports = function format (message) {
  if (regex.test(message)) return printf.apply(null, arguments)
  return toArray(arguments).join(' ')
}
