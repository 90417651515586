module.exports=[
  "flexDirection",
  "flexWrap",
  "justifyContent",
  "alignItems",
  "alignSelf",
  "flexGrow",
  "flexShrink",
  "flexBasis",
  "order",
  "flex"
]
