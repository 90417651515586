'use strict'

var h = require('virtual-dom/h')
var event = require('value-event/event')
var format = require('simple-format')
var qs = require('query-string')
var placeholder = require('./placeholder')

module.exports = {
  render: render
}

function render (state, data) {
  var style = {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%'
  }

  return h('photo-container', {style: style}, [
    placeholder.render(),
    renderPhoto(state, data),
    renderLoad(state, data)
  ])
}

var query = qs.stringify({
  preset: 'thumb'
})

function renderPhoto (state, data) {
  if (!data.url) return

  return h('photo', {
    style: {
      display: 'block',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundImage: format("url('%s?%s')", data.url, query),
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      borderRadius: '3px',
      opacity: Number(state.loaded),
      transform: state.loaded ? 'scale(1.0) translateY(0)' : 'scale(0.95) translateY(10px)',
      transition: 'all .5s ease-out'
    }
  })
}

function renderLoad (state, data) {
  return h('img', {
    src: [data.url, query].join('?'),
    'ev-load': event(state.channels.load),
    style: {
      position: 'absolute',
      top: 0,
      left: '-9999px'
    }
  })
}
