'use strict'

var State = require('dover')
var Observ = require('observ')
var toggle = require('observ-toggle')

var partial = require('ap').partial

var Button = require('./button')
var Photo = require('./photo')

module.exports = Image

function Image (data) {
  data = data || {}

  var state = State({
    button: Button(),
    loaded: Observ(data.loaded || false),
    zoomed: Observ(data.zoomed || false),
    channels: {
      load: load
    }
  })

  Button.onClick(state.button, partial(toggle, state.zoomed))

  return state
}

function load (state) {
  state.loaded.set(true)
}

Image.renderButton = function renderButton (state, data) {
  return Button.render(state.button, {
    zoomed: state.zoomed
  })
}

Image.renderPhoto = function renderPhoto (state, data) {
  return Photo.render(state, data)
}

