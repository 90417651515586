module.exports=[
  "trackSubmit",
  "trackClick",
  "trackLink",
  "trackForm",
  "pageview",
  "identify",
  "reset",
  "group",
  "track",
  "ready",
  "alias",
  "debug",
  "page",
  "once",
  "off",
  "on",
  "addSourceMiddleware"
]