'use strict'

var State = require('dover')
var Observ = require('observ')
var toggle = require('observ-toggle')

var h = require('virtual-dom/h')
var prefix = require('preflex')
var clickEvent = require('value-event/click')
var assign = require('xtend/mutable')

module.exports = Description

function Description (data) {
  data = data || {}

  return State({
    expanded: Observ(data.expanded || false),
    channels: {
      click: click
    }
  })
}

function click (state) {
  toggle(state.expanded)
}

Description.render = function render (state, data) {
  data = data || {}

  var style = {
    fontSize: data.large ? '16px' : '14px',
    lineHeight: '1.2em',
    marginBottom: '8px'
  }

  if (!state.expanded) {
    assign(style, prefix({
      display: 'flex'
    }))
  }

  return h('div', {style: style}, [
    renderText(state, data.text),
    renderToggle(state)
  ])
}

function renderText (state, text) {
  var style = {
    display: 'inline'
  }

  if (!state.expanded) {
    assign(style, {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    })
  }

  return h('p', {style: style}, text)
}

function renderToggle (state) {
  var text = !state.expanded ? 'More' : 'Less'

  return h('button', {
    style: {
      marginLeft: '.5em',
      minWidth: '3em',
      color: '#7898E7',
      fontWeight: 500,
      textDecoration: 'none'
    },
    type: 'button',
    'ev-click': clickEvent(state.channels.click, {}, {
      preventDefault: true
    })
  }, text)
}
